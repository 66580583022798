export enum DocumentTypes {
  referenceData = 'referenceData',
  translation = 'translation',
  record = 'record',
  recordType = 'recordType',
  dashboard = 'dashboard',
  filter = 'filter',
  workspaceData = 'workspaceData',
  userData = 'userData',
  termsOfUse = 'termsOfUse',
  emailTemplate = 'emailTemplate',
  workspace = 'workspace',
  member = 'member',
  integration = 'integration',
  serviceMember = 'serviceMember',
  serviceAccount = 'serviceAccount',
}

export enum RecordTypes {
  action = 'action',
  audit = 'audit',
  checklist = 'checklist',
  finding = 'finding',
  location = 'location',
  person = 'person',
  serviceAccount = 'serviceAccount',
  update = 'update',
  organisation = 'organisation',
  platformmember = 'platformmember',
  policy = 'policy',
  workspace = 'workspace',
}

export enum LevelPolicy {
  superadmin = 'superadmin',
  admin = 'admin',
  standard = 'standard',
  readonly = 'readonly',
  none = '',
}

export enum DefaultDocuments {
  defaultRecordType = 'common/recordType',
  defaultRecordTypeTranslation = 'common/translation.recordType.en-GB',
  defaultReferenceData = 'common/referenceData.referenceData',
  defaultReferenceDataTranslationEnGb = 'common/translation.referenceData.en-GB',
  defaultReferenceDataTranslationEsEs = 'common/translation.referenceData.es-ES',
  defaultReferenceDataTranslationHiIn = 'common/translation.referenceData.hi-IN',
  defaultTranslation = 'common/translation.recordType.default',
  defaultUserData = 'common/userData',
  defaultWorkspaceData = 'common/workspaceData',
  globalTranslation = 'common/translation.global.en-GB',
  emailTemplate = 'common/emailTemplate.welcome.en-GB',
  locationRecordType = 'common/recordType.location',
  locationTranslation = 'common/translation.recordType.location.en-GB',
  personRecordType = 'common/recordType.person',
  personTranslation = 'common/translation.recordType.person.en-GB',
  termsOfUseDocument = 'common/termsOfUse.termsOfUse1.en-GB',

  baseWorkspaceData = 'common/baseWorkspaceData',

  starterRecordRecordType = 'starter/recordType.record',
  starterRecordTranslation = 'starter/translation.recordType.record.en-GB',
  starterReferenceData = 'starter/referenceData.referenceData',
  starterReferenceDataTranslation = 'starter/translation.referenceData.en-GB',
  starterWorkspaceDataDocument = 'starter/workspaceData',
  starterHomePageDocument = 'starter/dashboard.home',
  starterHomePageTranslation = 'starter/translation.dashboard.home.en-GB',
  cmpOrganisationRecordType = 'cmp/recordType.organisation',
  cmpOrganisationTranslation = 'cmp/translation.recordType.organisation.en-GB',
  cmpPlatformMemberRecordType = 'cmp/recordType.platformmember',
  cmpPlatformMemberTranslation = 'cmp/translation.recordType.platformmember.en-GB',
  cmpPolicyRecordType = 'cmp/recordType.policy',
  cmpPolicyTranslation = 'cmp/translation.recordType.policy.en-GB',
  cmpWorkspaceRecordType = 'cmp/recordType.workspace',
  cmpWorkspaceTranslation = 'cmp/translation.recordType.workspace.en-GB',
  cmpReferenceDataDocument = 'cmp/referenceData.referenceData',
  cmpWorkspaceDataDocument = 'cmp/workspaceData',
  cmpReferenceDataTranslation = 'cmp/translation.referenceData.en-GB',
  cmpHomePageDocument = 'cmp/dashboard.home',
  cmpHomePageTranslation = 'cmp/translation.dashboard.home.en-GB',
  smartAuditV2globalTranslation = 'smartAuditV2/translation.global.en-GB',
  smartAuditV2recordTypeTranslation = 'smartAuditV2/translation.recordType.en-GB',
  smartAuditV2locationRecordType = 'smartAuditV2/recordType.location',
  smartAuditV2locationTranslation = 'smartAuditV2/translation.recordType.location.en-GB',
  smartAuditV2personRecordType = 'smartAuditV2/recordType.person',
  smartAuditV2personTranslation = 'smartAuditV2/translation.recordType.person.en-GB',
  smartAuditV2ActionRecordType = 'smartAuditV2/recordType.action',
  smartAuditV2ActionTranslation = 'smartAuditV2/translation.recordType.action.en-GB',
  smartAuditV2AuditRecordType = 'smartAuditV2/recordType.audit',
  smartAuditV2AuditTranslation = 'smartAuditV2/translation.recordType.audit.en-GB',
  smartAuditV2ChecklistRecordType = 'smartAuditV2/recordType.checklist',
  smartAuditV2ChecklistTranslation = 'smartAuditV2/translation.recordType.checklist.en-GB',
  smartAuditV2FindingRecordType = 'smartAuditV2/recordType.finding',
  smartAuditV2FindingTranslation = 'smartAuditV2/translation.recordType.finding.en-GB',
  smartAuditV2ReferenceDataDocument = 'smartAuditV2/referenceData.referenceData',
  smartAuditV2ReferenceDataTranslation = 'smartAuditV2/translation.referenceData.en-GB',
  smartAuditV2HomePageDocument = 'smartAuditV2/dashboard.home',
  smartAuditV2HomePageTranslation = 'smartAuditV2/translation.dashboard.home.en-GB',
  smartAuditV2WorkspaceDataDocument = 'smartAuditV2/workspaceData',
  automationGlobalTranslation = 'automation/translation.global.en-GB',
  automationRecordTypeTranslation = 'automation/translation.recordType.en-GB',
  automationLocationRecordType = 'automation/recordType.location',
  automationLocationTranslation = 'automation/translation.recordType.location.en-GB',
  automationPersonRecordType = 'automation/recordType.person',
  automationPersonTranslation = 'automation/translation.recordType.person.en-GB',
  automationActionRecordType = 'automation/recordType.action',
  automationActionTranslation = 'automation/translation.recordType.action.en-GB',
  automationAuditRecordType = 'automation/recordType.audit',
  automationAuditTranslation = 'automation/translation.recordType.audit.en-GB',
  automationChecklistRecordType = 'automation/recordType.checklist',
  automationChecklistTranslation = 'automation/translation.recordType.checklist.en-GB',
  automationFindingRecordType = 'automation/recordType.finding',
  automationFindingTranslation = 'automation/translation.recordType.finding.en-GB',
  automationReferenceDataDocument = 'automation/referenceData.referenceData',
  automationReferenceDataTranslation = 'automation/translation.referenceData.en-GB',
  automationHomePageDocument = 'automation/dashboard.home',
  automationHomePageTranslation = 'automation/translation.dashboard.home.en-GB',
  automationWorkspaceDataDocument = 'automation/workspaceData',
  takeawayGlobalTranslation = 'takeaway/translation.global.en-GB',
  takeawayRecordTypeTranslation = 'takeaway/translation.recordType.en-GB',
  takeawayLocationRecordType = 'takeaway/recordType.location',
  takeawayLocationTranslation = 'takeaway/translation.recordType.location.en-GB',
  takeawayPersonRecordType = 'takeaway/recordType.person',
  takeawayPersonTranslation = 'takeaway/translation.recordType.person.en-GB',
  takeawayActionRecordType = 'takeaway/recordType.action',
  takeawayActionTranslation = 'takeaway/translation.recordType.action.en-GB',
  takeawayAuditRecordType = 'takeaway/recordType.audit',
  takeawayAuditTranslation = 'takeaway/translation.recordType.audit.en-GB',
  takeawayChecklistRecordType = 'takeaway/recordType.checklist',
  takeawayChecklistTranslation = 'takeaway/translation.recordType.checklist.en-GB',
  takeawayFindingRecordType = 'takeaway/recordType.finding',
  takeawayFindingTranslation = 'takeaway/translation.recordType.finding.en-GB',
  takeawayReferenceDataDocument = 'takeaway/referenceData.referenceData',
  takeawayReferenceDataTranslation = 'takeaway/translation.referenceData.en-GB',
  takeawayHomePageDocument = 'takeaway/dashboard.home',
  takeawayHomePageTranslation = 'takeaway/translation.dashboard.home.en-GB',
  takeawayWorkspaceDataDocument = 'takeaway/workspaceData',
  takeawayTermsOfUseDocument = 'takeaway/termsOfUse.takeawayTerms.en-GB',

  plusPremiumGlobalTranslation = 'plus-premium/translation.global.en-GB',
  plusPremiumRecordTypeTranslation = 'plus-premium/translation.recordType.en-GB',
  plusPremiumLocationRecordType = 'plus-premium/recordType.location',
  plusPremiumLocationTranslation = 'plus-premium/translation.recordType.location.en-GB',
  plusPremiumPersonRecordType = 'plus-premium/recordType.person',
  plusPremiumPersonTranslation = 'plus-premium/translation.recordType.person.en-GB',
  plusPremiumActionRecordType = 'plus-premium/recordType.action',
  plusPremiumActionTranslation = 'plus-premium/translation.recordType.action.en-GB',
  plusPremiumAuditRecordType = 'plus-premium/recordType.audit',
  plusPremiumAuditTranslation = 'plus-premium/translation.recordType.audit.en-GB',
  plusPremiumChecklistRecordType = 'plus-premium/recordType.checklist',
  plusPremiumChecklistTranslation = 'plus-premium/translation.recordType.checklist.en-GB',
  plusPremiumFindingRecordType = 'plus-premium/recordType.finding',
  plusPremiumFindingTranslation = 'plus-premium/translation.recordType.finding.en-GB',
  plusPremiumReferenceDataDocument = 'plus-premium/referenceData.referenceData',
  plusPremiumReferenceDataTranslation = 'plus-premium/translation.referenceData.en-GB',
  plusPremiumHomePageDocument = 'plus-premium/dashboard.home',
  plusPremiumHomePageTranslation = 'plus-premium/translation.dashboard.home.en-GB',
  plusPremiumWorkspaceDataDocument = 'plus-premium/workspaceData',
  plusPremiumTermsOfUseDocument = 'plus-premium/termsOfUse.plusPremiumTerms.en-GB',

  integrationFindingRecordType = 'integration/recordType.finding',
  integrationFindingTranslation = 'integration/translation.recordType.finding.en-GB',
  integrationReferenceDataDocument = 'integration/referenceData.referenceData',
  integrationReferenceDataTranslation = 'integration/translation.referenceData.en-GB',
  integrationRecordType = 'integration/recordType.integration',
  integrationTranslation = 'integration/translation.recordType.integration.en-GB',
  integrationWorkspaceRecordType = 'integration/recordType.workspace',
  integrationWorkspaceDataDocument = 'integration/workspaceData',
  integrationWorkspaceTranslation = 'integration/translation.recordType.workspace.en-GB',
  serviceAccountRecordType = 'common/recordType.serviceAccount',
  serviceAccountTranslation = 'common/translation.recordType.serviceAccount.en-GB',
}

export enum CodeLanguages {
  yaml = 'yaml',
  json = 'json',
}
