import { Injectable } from '@angular/core';
import { DocumentApiService, Library, LibraryService } from '@core/services';
import { DefaultDocuments, DocumentTypes } from '@shared/reference';
import pLimit from 'p-limit';
import { environment } from '@env';
import { Document, ProgressIterable, ProgressUpdate } from '@core/models';

export class PopulateResult {
  constructor(public recordTypes: string[]) { }
}

export interface Template {
  id: string;
  name: string;
  workspaceAdminLimit: number;
  workspaceStandardLimit: number;
}

export interface DocumentItem {
  id: string;
  libraryId: Library;
  templateIds: string[];
  document: Document | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class PopulateService {
  private isInitialised = false;

  constructor(
    private readonly documentApiService: DocumentApiService,
    private readonly library: LibraryService,
  ) { }

  async init(): Promise<boolean> {
    if (!this.isInitialised) {
      try {
        const isLoaded = await this.loadFromLibrary();
        this.isInitialised = isLoaded;
        return isLoaded;
      } catch (e) {
        console.error(e);
      }
    }
    return this.isInitialised;
  }

  getDefaultTemplateId(): string {
    return 'smartAuditV2';
  }

  getTemplates(forReference: boolean): Template[] {
    if (forReference) {
      return [
        {
          id: 'base',
          name: 'Base (Person and Location)',
          workspaceAdminLimit: -1,
          workspaceStandardLimit: -1
        },
        {
          id: 'blank',
          name: 'Blank Template',
          workspaceAdminLimit: -1,
          workspaceStandardLimit: -1
        }
      ].sort((a, b) => a.name.localeCompare(b.name));
    }

    const templates: Template[] = [
      {
        id: 'smartAuditV2',
        name: 'Plus Training',
        workspaceAdminLimit: 1,
        workspaceStandardLimit: 30
      },
      {
        id: 'integration',
        name: 'Integrations',
        workspaceAdminLimit: -1,
        workspaceStandardLimit: -1
      },
      {
        id: 'starter',
        name: 'Starter',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 0
      },
      {
        id: 'takeaway',
        name: 'Plus Basic',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 0
      },
      {
        id: 'plusPremium',
        name: 'Plus Premium',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 0
      },
      {
        id: 'blank',
        name: 'Blank Workspace',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 10
      }

    ];

    if (!environment.production) {
      templates.push({
        id: 'automation',
        name: 'Automation',
        workspaceAdminLimit: 1,
        workspaceStandardLimit: 30
      });
    }

    templates.sort((a, b) => a.name.localeCompare(b.name));
    return templates;
  }

  async loadFromLibrary(): Promise<boolean> {
    const limit = pLimit(10);
    const promises: Promise<Document>[] = [];
    for (const item of this.documentCache) {
      promises.push(
        limit(() => this.library
          .getItem(item.libraryId)
          .then(d => {
            item.document = d;
            return d;
          })));
    }

    try {
      await Promise.all(promises);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private documentCache: DocumentItem[] = [
    {
      id: DefaultDocuments.defaultRecordType,
      libraryId: Library.defaultRecordType,
      templateIds: [],
      document: undefined,
    },
    {
      id: DefaultDocuments.defaultTranslation,
      libraryId: Library.defaultRecordTypeTranslation,
      templateIds: [],
      document: undefined,
    },
    {
      id: DefaultDocuments.globalTranslation,
      libraryId: Library.globalTranslation,
      templateIds: ['starter', 'base', 'integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.locationRecordType,
      libraryId: Library.locationRecordType,
      templateIds: ['starter', 'base', 'integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.locationTranslation,
      libraryId: Library.locationTranslation,
      templateIds: ['starter', 'base', 'integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.personRecordType,
      libraryId: Library.personRecordType,
      templateIds: ['starter', 'base', 'integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.personTranslation,
      libraryId: Library.personTranslation,
      templateIds: ['starter', 'base', 'integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.defaultRecordTypeTranslation,
      libraryId: Library.recordTypeTranslation,
      templateIds: ['starter', 'base', 'integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.starterRecordRecordType,
      libraryId: Library.starterRecordRecordType,
      templateIds: ['starter'],
      document: undefined,
    },
    {
      id: DefaultDocuments.defaultUserData,
      libraryId: Library.defaultUserDataDocument,
      templateIds: ['starter', 'smartAuditV2', 'automation', 'takeaway', 'base' ],
      document: undefined,
    },
    {
      id: DefaultDocuments.termsOfUseDocument,
      libraryId: Library.defaultTermsOfUse,
      templateIds: ['starter'],
      document: undefined,
    },
    {
      id: DefaultDocuments.emailTemplate,
      libraryId: Library.defaultEmailTemplate,
      templateIds: [
        'starter',
        'smartAuditV2',
        'automation',
        'takeaway',
        'plusPremium',
      ],
      document: undefined,
    },
    {
      id: DefaultDocuments.starterRecordTranslation,
      libraryId: Library.starterRecordTranslation,
      templateIds: ['starter'],
      document: undefined,
    },
    {
      id: DefaultDocuments.starterReferenceData,
      libraryId: Library.starterReferenceData,
      templateIds: ['starter', 'base'],
      document: undefined,
    },
    {
      id: DefaultDocuments.starterReferenceDataTranslation,
      libraryId: Library.starterReferenceDataTranslation,
      templateIds: ['starter', 'base'],
      document: undefined,
    },
    {
      id: DefaultDocuments.starterHomePageDocument,
      libraryId: Library.starterHomePageDocument,
      templateIds: ['starter'],
      document: undefined,
    },
    {
      id: DefaultDocuments.starterHomePageTranslation,
      libraryId: Library.starterHomePageTranslation,
      templateIds: ['starter'],
      document: undefined,
    },
    {
      id: DefaultDocuments.starterWorkspaceDataDocument,
      libraryId: Library.starterWorkspaceDataDocument,
      templateIds: ['starter'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2globalTranslation,
      libraryId: Library.smartAuditV2GlobalTranslation,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2locationRecordType,
      libraryId: Library.smartAuditV2LocationRecordType,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2locationTranslation,
      libraryId: Library.smartAuditV2LocationTranslation,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2personRecordType,
      libraryId: Library.smartAuditV2PersonRecordType,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2personTranslation,
      libraryId: Library.smartAuditV2PersonTranslation,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2recordTypeTranslation,
      libraryId: Library.smartAuditV2RecordTypeTranslation,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2AuditRecordType,
      libraryId: Library.smartAuditV2AuditRecordType,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2AuditTranslation,
      libraryId: Library.smartAuditV2AuditTranslation,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2ChecklistRecordType,
      libraryId: Library.smartAuditV2ChecklistRecordType,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2ChecklistTranslation,
      libraryId: Library.smartAuditV2ChecklistTranslation,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2FindingRecordType,
      libraryId: Library.smartAuditV2FindingRecordType,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2FindingTranslation,
      libraryId: Library.smartAuditV2FindingTranslation,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2ReferenceDataDocument,
      libraryId: Library.smartAuditV2ReferenceData,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2ReferenceDataTranslation,
      libraryId: Library.smartAuditV2ReferenceDataTranslation,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2HomePageDocument,
      libraryId: Library.smartAuditV2HomePageDocument,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2HomePageTranslation,
      libraryId: Library.smartAuditV2HomePageTranslation,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.smartAuditV2WorkspaceDataDocument,
      libraryId: Library.smartAuditV2WorkspaceDataDocument,
      templateIds: ['smartAuditV2'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationGlobalTranslation,
      libraryId: Library.automationGlobalTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationLocationRecordType,
      libraryId: Library.automationLocationRecordType,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationLocationTranslation,
      libraryId: Library.automationLocationTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationPersonRecordType,
      libraryId: Library.automationPersonRecordType,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationPersonTranslation,
      libraryId: Library.automationPersonTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationRecordTypeTranslation,
      libraryId: Library.automationRecordTypeTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationActionRecordType,
      libraryId: Library.automationActionRecordType,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationActionTranslation,
      libraryId: Library.automationActionTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationAuditRecordType,
      libraryId: Library.automationAuditRecordType,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationAuditTranslation,
      libraryId: Library.automationAuditTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationChecklistRecordType,
      libraryId: Library.automationChecklistRecordType,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationChecklistTranslation,
      libraryId: Library.automationChecklistTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationFindingRecordType,
      libraryId: Library.automationFindingRecordType,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationFindingTranslation,
      libraryId: Library.automationFindingTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationReferenceDataDocument,
      libraryId: Library.automationReferenceData,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationReferenceDataTranslation,
      libraryId: Library.automationReferenceDataTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationHomePageDocument,
      libraryId: Library.automationHomePageDocument,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationHomePageTranslation,
      libraryId: Library.automationHomePageTranslation,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.automationWorkspaceDataDocument,
      libraryId: Library.automationWorkspaceDataDocument,
      templateIds: ['automation'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayGlobalTranslation,
      libraryId: Library.takeawayGlobalTranslation,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayLocationRecordType,
      libraryId: Library.takeawayLocationRecordType,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayLocationTranslation,
      libraryId: Library.takeawayLocationTranslation,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayPersonRecordType,
      libraryId: Library.takeawayPersonRecordType,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayPersonTranslation,
      libraryId: Library.takeawayPersonTranslation,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayRecordTypeTranslation,
      libraryId: Library.takeawayRecordTypeTranslation,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayAuditRecordType,
      libraryId: Library.takeawayAuditRecordType,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayAuditTranslation,
      libraryId: Library.takeawayAuditTranslation,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayChecklistRecordType,
      libraryId: Library.takeawayChecklistRecordType,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayChecklistTranslation,
      libraryId: Library.takeawayChecklistTranslation,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayFindingRecordType,
      libraryId: Library.takeawayFindingRecordType,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayFindingTranslation,
      libraryId: Library.takeawayFindingTranslation,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayReferenceDataDocument,
      libraryId: Library.takeawayReferenceData,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayReferenceDataTranslation,
      libraryId: Library.takeawayReferenceDataTranslation,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayHomePageDocument,
      libraryId: Library.takeawayHomePageDocument,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayHomePageTranslation,
      libraryId: Library.takeawayHomePageTranslation,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayWorkspaceDataDocument,
      libraryId: Library.takeawayWorkspaceDataDocument,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.takeawayTermsOfUseDocument,
      libraryId: Library.takeawayTermsOfUseDocument,
      templateIds: ['takeaway'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumGlobalTranslation,
      libraryId: Library.plusPremiumGlobalTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumLocationRecordType,
      libraryId: Library.plusPremiumLocationRecordType,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumLocationTranslation,
      libraryId: Library.plusPremiumLocationTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumPersonRecordType,
      libraryId: Library.plusPremiumPersonRecordType,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumPersonTranslation,
      libraryId: Library.plusPremiumPersonTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumRecordTypeTranslation,
      libraryId: Library.plusPremiumRecordTypeTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumActionRecordType,
      libraryId: Library.plusPremiumActionRecordType,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumActionTranslation,
      libraryId: Library.plusPremiumActionTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumAuditRecordType,
      libraryId: Library.plusPremiumAuditRecordType,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumAuditTranslation,
      libraryId: Library.plusPremiumAuditTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumChecklistRecordType,
      libraryId: Library.plusPremiumChecklistRecordType,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumChecklistTranslation,
      libraryId: Library.plusPremiumChecklistTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumFindingRecordType,
      libraryId: Library.plusPremiumFindingRecordType,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumFindingTranslation,
      libraryId: Library.plusPremiumFindingTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumReferenceDataDocument,
      libraryId: Library.plusPremiumReferenceData,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumReferenceDataTranslation,
      libraryId: Library.plusPremiumReferenceDataTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumHomePageDocument,
      libraryId: Library.plusPremiumHomePageDocument,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumHomePageTranslation,
      libraryId: Library.plusPremiumHomePageTranslation,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumWorkspaceDataDocument,
      libraryId: Library.plusPremiumWorkspaceDataDocument,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.plusPremiumTermsOfUseDocument,
      libraryId: Library.plusPremiumTermsOfUseDocument,
      templateIds: ['plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.defaultWorkspaceData,
      libraryId: Library.defaultWorkspaceData,
      templateIds: ['blank'],
      document: undefined,
    },
    {
      id: DefaultDocuments.baseWorkspaceData,
      libraryId: Library.baseWorkspaceData,
      templateIds: ['base'],
      document: undefined,
    },
    {
      id: DefaultDocuments.integrationFindingRecordType,
      libraryId: Library.integrationFindingRecordType,
      templateIds: ['integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.integrationFindingTranslation,
      libraryId: Library.integrationFindingTranslation,
      templateIds: ['integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.integrationReferenceDataDocument,
      libraryId: Library.integrationReferenceDataDocument,
      templateIds: ['integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.integrationReferenceDataTranslation,
      libraryId: Library.integrationReferenceDataTranslation,
      templateIds: ['integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.integrationRecordType,
      libraryId: Library.integrationRecordType,
      templateIds: ['integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.integrationTranslation,
      libraryId: Library.integrationTranslation,
      templateIds: ['integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.integrationWorkspaceRecordType,
      libraryId: Library.integrationWorkspaceRecordType,
      templateIds: ['integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.integrationWorkspaceDataDocument,
      libraryId: Library.integrationWorkspaceDataDocument,
      templateIds: ['integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.integrationWorkspaceTranslation,
      libraryId: Library.integrationWorkspaceTranslation,
      templateIds: ['integration'],
      document: undefined,
    },
    {
      id: DefaultDocuments.serviceAccountRecordType,
      libraryId: Library.serviceAccountRecordType,
      templateIds: ['starter', 'smartAuditV2', 'integration', 'plusPremium'],
      document: undefined,
    },
    {
      id: DefaultDocuments.serviceAccountTranslation,
      libraryId: Library.serviceAccountTranslation,
      templateIds: ['starter', 'smartAuditV2', 'integration', 'plusPremium'],
      document: undefined,
    },
  ];

  getDefaultDocument(id: string): Document | undefined {
    const item = this.documentCache.find(item => item.id === id);
    if (item) {
      return item.document;
    }
    return undefined;
  }

  getDocumentItemsForTemplate(templateId: string): DocumentItem[] {
    return this.documentCache.filter(item => item.templateIds.includes(templateId));
  }

  async *populate(
    workspaceId: string,
    templateId: string,
    workspaceDisplayName: string): ProgressIterable<PopulateResult> {

    if (!this.isInitialised) {
      throw new Error('Not initialised');
    }

    const recordTypes: string[] = [];

    // Loop through documentCache to create record types
    yield new ProgressUpdate(0, 'Creating record types');
    for (const item of this.documentCache.filter(item => item.templateIds.includes(templateId)
      && item.document!.documentType === DocumentTypes.recordType)) {

      await this.documentApiService.createRecordType(item.document!, workspaceId);
      if (item.document!.name) {
        recordTypes.push(item.document!.name);
      }
    }

    // Find the workspace data in the documentCache to create
    yield new ProgressUpdate(60, 'Creating workspace data');
    for (const item of this.documentCache.filter(item => item.templateIds.includes(templateId)
      && item.document!.documentType === DocumentTypes.workspaceData)) {

      const content = item.document!.content;

      if (content.info !== null && content.info !== undefined) {
        // Update the workspace info properties
        item.document!.content.info.workspaceDisplayName = workspaceDisplayName;
        item.document!.content.info.workspaceUrl = environment.workspaceUrl;
        item.document!.content.info.applicationLogoDataUrl = '';
        item.document!.content.info.reportLogoDataUrl = '';
      }

      await this.documentApiService.createDocument({
        id: workspaceId,
        documentId: workspaceId,
        documentType: item.document!.documentType,
        content: item.document!.content,
        isPrivate: false
      }, workspaceId);
    }

    // Create the remaining documents
    yield new ProgressUpdate(70, 'Creating documents');
    for (const item of this.documentCache.filter(item => item.templateIds.includes(templateId)
      && item.document!.documentType !== DocumentTypes.recordType
      && item.document!.documentType !== DocumentTypes.workspaceData)) {

      await this.documentApiService.createDocument(item.document!, workspaceId);
    }

    yield new PopulateResult(recordTypes);
  }
}
